<template>
  <div ref="viewer" />
</template>

<script>
/* eslint-disable */
import WebViewer from '@pdftron/pdfjs-express-viewer'
import axios from 'axios'

export default {
  name: 'WebViewer',
  mounted: async function () {
    try {
      const certId = this.$route.params.id
      const docId = this.$route.params.documentId

      if (certId) {
        let response = await axios.get(`/api/getTrainingDataFromCertificate/${certId}`)
        const trainingData = response.data

        if (trainingData && trainingData.length && trainingData) {
          this.startWebViewer(`/api/file/${trainingData}`)
        } else {
          this.showErrorToastAndRedirectToApp()
        }
      } else if (docId) {
        this.startWebViewer(`/api/file/${docId}`)
      }
    } catch (e) {
      this.showErrorToastAndRedirectToApp()
    }
  },
  methods: {
    showErrorToastAndRedirectToApp() {
      this.$toast.error(this.$t('dialog.the-document-could-not-be-loaded'))
      this.$router.push('/login')
    },
    startWebViewer(url) {
      WebViewer(
        {
          path: '/lib',
          initialDoc: url,
          licenseKey: process.env.NODE_ENV === 'development' ? 'za57M8ZPrd5Tkryz7KG2' : 'YgxzV8assHNxkWukBS0Z',
        },
        this.$refs.viewer,
      ).then((instance) => {
        // now you can access APIs through the WebViewer instance
        const { Core, UI } = instance
        // adding an event listener for when a document is loaded
        Core.documentViewer.addEventListener('documentLoaded', () => {
          console.log('document loaded')
        })
        // adding an event listener for when the page number has changed
        Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
          console.log(`Page number is: ${pageNumber}`)
        })
        // UI.disableFeatures([UI.Feature.Print])
        // adds a button to the header that on click sets the page to the next page
        // UI.setHeaderItems(header => {
        //   header.push({
        //     type: 'actionButton',
        //     img: 'https://icons.getbootstrap.com/assets/icons/caret-right-fill.svg',
        //     onClick: () => {
        //       const currentPage = Core.documentViewer.getCurrentPage()
        //       const totalPages = Core.documentViewer.getPageCount()
        //       const atLastPage = currentPage === totalPages
        //       if (atLastPage) {
        //         Core.documentViewer.setCurrentPage(1)
        //       } else {
        //         Core.documentViewer.setCurrentPage(currentPage + 1)
        //       }
        //     },
        //   })
        // })
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  width: 100%;
  height: 100vh;
}
</style>
